import type { ProcessedDataset } from 'lib/api-models';
import { assertDefined, assertEnvVar } from 'lib/guards';
import {
  formatOrganDescr,
  formatStringDate,
  getMetadataByName,
} from 'lib/helpers';
import { Link, useSearch } from 'wouter';
import { useBrowserLocation } from 'wouter/use-browser-location';

import styles from './DatasetCard.module.css';

const ICATP = import.meta.env.VITE_ICATP;

function DatasetCard(props: { dataset: ProcessedDataset; sessionId: string }) {
  const { id, parameters, gallery } = props.dataset;
  const { sessionId } = props;

  const [location] = useBrowserLocation();
  const queryParams = useSearch();
  const backURL = queryParams ? [location, queryParams].join('?') : location;

  const previousId = window.history.state?.previousId;

  const url = `~/datasets/${encodeURIComponent(id)}`;
  const thumbnail = gallery.find((f) => f.name.startsWith('thumbnail'));

  assertEnvVar(ICATP, 'VITE_ICATP');

  const organPosition = getMetadataByName(
    parameters,
    'SamplePatient_organ_description',
  )?.split('_')[0];

  const organName =
    getMetadataByName(parameters, 'SamplePatient_organ_name') || undefined;
  assertDefined(organName);

  const organInfo = organPosition
    ? formatOrganDescr(organName, organPosition)
    : organName;

  const scanDate = getMetadataByName(parameters, 'startDate');

  return (
    <Link
      className={styles.datasetCard}
      to={url}
      state={{
        backLabel: 'results',
        backURL,
      }}
      data-previousid={id.toString() === previousId || undefined}
    >
      <div className={styles.datasetContent}>
        <h2 className={styles.datasetTitle}>
          {getMetadataByName(parameters, 'datasetName')}
        </h2>
        <div className={styles.datasetContext}>
          <div className={styles.infoPill}>
            {getMetadataByName(parameters, 'SamplePatient_number')}
          </div>
          <div className={styles.infoPill}>{organInfo}</div>
          <div className={styles.infoPill}>
            {scanDate && formatStringDate(scanDate)}
          </div>
        </div>
        <p className={styles.datasetAbstract}>
          {getMetadataByName(parameters, 'DOI_abstract')}
        </p>
      </div>
      <div className={styles.thumbnail}>
        {thumbnail && (
          // Put loading="lazy" before src. See comment in lib/Dataset/Media.tsx
          <img
            alt=""
            loading="lazy"
            src={`${ICATP}/resource/${sessionId}/file/download?resourceId=${thumbnail.id}`}
          />
        )}
      </div>
    </Link>
  );
}

export default DatasetCard;
