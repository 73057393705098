import { useToggle } from '@react-hookz/web';
import { useNeuroglancerUrl } from 'lib/api-hooks';

import styles from './NeuroglancerWindow.module.css';

interface Props {
  resourceId: string;
}

function NeuroglancerWindow(props: Props) {
  const { resourceId } = props;
  const neuroglancerLink = useNeuroglancerUrl(resourceId);
  const [showNeuroglancer, toggleNeuroglancer] = useToggle();

  return (
    <div className={styles.neuroglancerContainer}>
      {!showNeuroglancer ? (
        <div>
          <img
            className={styles.neuroglancerImage}
            src="../../logos/neuroglancer_empty.png"
            alt=""
          />
          <button
            className={styles.neuroglancerButton}
            type="button"
            onClick={() => toggleNeuroglancer(true)}
          >
            Click here to visualise in Neuroglancer
          </button>
        </div>
      ) : (
        <iframe
          className={styles.neuroglancerWindow}
          src={`${neuroglancerLink}`}
          title="neuroglancer-frame"
          allowFullScreen
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      )}
    </div>
  );
}
export default NeuroglancerWindow;
