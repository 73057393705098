import { ExploreLayout } from 'lib/explore-layout';
import { usePageTracking } from 'lib/hooks';

import DatasetList from './DatasetList';
import DonorList from './DonorList';
import OrganList from './OrganList';

function ExplorePage() {
  usePageTracking();

  const layerList = {
    firstLayer: {
      title: 'Donors',
      component: <DonorList />,
    },
    secondLayer: { title: 'Organs', component: <OrganList /> },
    thirdLayer: {
      title: 'Datasets',
      component: <DatasetList />,
    },
  };

  return <ExploreLayout layerList={layerList} />;
}

export default ExplorePage;
