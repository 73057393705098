import { useMultipleMetadataParams } from 'lib/api-hooks';
import { CATALOG_UNIT } from 'lib/unit-catalog';
import { FiUser } from 'react-icons/fi';
import { Link, useRoute } from 'wouter';

import styles from './DonorCard.module.css';

interface Props {
  donor: string;
}

const NAMES = {
  sex: 'SamplePatient_sex',
  age: 'SamplePatient_age',
  size: 'SamplePatient_size',
  weight: 'SamplePatient_weight',
  info: 'SamplePatient_info',
};

function DonorCard(props: Props) {
  const { donor } = props;

  const queryParam = `SamplePatient_number~eq~${donor}`;

  const donorInfos = useMultipleMetadataParams(NAMES, queryParam);

  const [isActive] = useRoute(`/${donor}/*?`);

  return (
    <Link
      className={isActive ? styles.activeDonorCard : styles.donorCard}
      to={`/${encodeURIComponent(donor)}`}
    >
      <div className={styles.cardTitle}>
        <div className={styles.fiUser}>
          <FiUser />
        </div>
        <h2 className={styles.donorTitle}>{donor}</h2>
      </div>
      <div className={styles.cardInfo} data-keywords>
        {donorInfos.sex && (
          <div className={styles.infoPill}>
            {donorInfos.sex} {CATALOG_UNIT[NAMES.sex]}
          </div>
        )}
        {donorInfos.age && (
          <div className={styles.infoPill}>
            {donorInfos.age} {CATALOG_UNIT[NAMES.age]}
          </div>
        )}
        {donorInfos.weight && (
          <div className={styles.infoPill}>
            {donorInfos.weight} {CATALOG_UNIT[NAMES.weight]}
          </div>
        )}
        {/* eslint-disable-next-line unicorn/explicit-length-check */}
        {donorInfos.size && (
          <div className={styles.infoPill}>
            {donorInfos.size} {CATALOG_UNIT[NAMES.size]}
          </div>
        )}
      </div>
      {donorInfos.info && (
        <p className={styles.donorAbstract}>
          {donorInfos.info} {CATALOG_UNIT[NAMES.info]}
        </p>
      )}
    </Link>
  );
}

export default DonorCard;
