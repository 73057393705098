import { useEffect, useMemo } from 'react';
import { useLocation, useSearch } from 'wouter';

// Based on https://stackoverflow.com/a/12709880
// otherwise, TS says that property _paq doesn't exist on type Window
declare global {
  interface Window {
    _paq: string[][];
  }
}

export function useScrollToTop() {
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);
}

export function usePageTracking() {
  const [location] = useLocation();

  useEffect(() => {
    // Skip `/reconstructions`, which redirects to first reconstruction
    if (/reconstructions\/?$/u.test(location)) {
      return;
    }

    window._paq.push(
      ['setCustomUrl', window.location.href],
      ['trackPageView', document.title],
    );
  }, [location]);
}

export function useSearchParams() {
  const search = useSearch();
  return useMemo(() => new URLSearchParams(search), [search]);
}
