import byteSize from 'byte-size';
import type { PropsWithChildren } from 'react';
import { FiDownload } from 'react-icons/fi';

import { useFileList, useIcatSessionId } from '../api-hooks';
import { trackLink } from '../helpers';
import { useSearchParams } from '../hooks';
import Pagination from '../Pagination/Pagination';
import { querySplitter } from '../Pagination/pagination';
import styles from './Files.module.css';
import GlobusLink from './GlobusLink';

interface Props {
  doi: string;
  datasetId: string;
  handleClick?: (evt: React.MouseEvent<HTMLAnchorElement>) => void;
  excludeGallery?: boolean;
  sortByFilesize?: boolean;
}

const LICENSE_NAME = import.meta.env.VITE_LICENSE_NAME;
const LICENSE_URL = import.meta.env.VITE_LICENSE_URL;

function Files(props: PropsWithChildren<Props>) {
  const {
    doi,
    datasetId,
    handleClick,
    excludeGallery,
    sortByFilesize,
    children,
  } = props;
  const query = useSearchParams();

  const parametersInfo = query ? Object.fromEntries(query) : {};

  const { paginationQuery } = querySplitter(parametersInfo);

  const filesList = useFileList(datasetId, excludeGallery, paginationQuery);
  const { files } = filesList;
  const filesSort = sortByFilesize
    ? [...files].sort((a, b) => b.fileSize - a.fileSize)
    : files;

  const globusFilePath = files[0].location;
  const paginationInfo = files[0].meta;

  const sessionId = useIcatSessionId();

  return (
    <div className={styles.fileSection}>
      {children}

      {files.length > 1 && (
        <GlobusLink filePath={globusFilePath} handleClick={handleClick} />
      )}

      <p className={styles.dataPolicy}>
        These datasets are made freely available under the{' '}
        <a
          className={styles.infoLink}
          href={LICENSE_URL}
          target="_blank"
          rel="noreferrer"
        >
          {LICENSE_NAME} licence
        </a>
        . Publications using these datasets are required to{' '}
        <a
          className={styles.infoLink}
          href={`http://doi.org/${doi}`}
          target="_blank"
          rel="noreferrer"
          onClick={(evt) => trackLink(evt)}
        >
          cite the&nbsp;DOI
        </a>
        .
      </p>

      {paginationInfo.page.total > 10 && (
        <Pagination paginationInfo={paginationInfo} />
      )}

      {filesSort.map((f) => (
        <a
          key={f.id}
          className={styles.downloadFile}
          data-alt
          href={`https://ids.esrf.fr/ids/getData?sessionId=${sessionId}&datafileIds=${f.id}`}
          download={`${f.shortName}.${f.ext.toLowerCase()}`}
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
        >
          <span>{f.ext}</span>
          <span>
            <span>
              <span className={styles.fileName}>{f.shortName} </span>
              <span className={styles.sizeByte}>
                &mdash; {byteSize(f.fileSize).toString()}
              </span>
            </span>
          </span>
          <span>
            <FiDownload />
          </span>
        </a>
      ))}
    </div>
  );
}

export default Files;
