import type { ReactNode } from 'react';
import { FiArrowLeft, FiHome } from 'react-icons/fi';
import { Link, useLocation } from 'wouter';

import { useAppStore } from '../stores';
import styles from './NavBar.module.css';
import NavLink from './NavLink';

interface Props {
  isDesktop: boolean;
  children: ReactNode;
  title?: string;
  image?: { name: string; width: string; height: string };
}

function NavBar(props: Props) {
  const { isDesktop, children, title, image } = props;

  const [location] = useLocation();

  const numResults = useAppStore((state) => state.numResults);

  const { backLabel, backURL } = window.history.state || {};
  const splitLocation = location.split('/');
  const previousId = splitLocation[splitLocation.length - 1];

  return (
    <nav className={styles.backgroundBanner}>
      <div className={styles.banner}>
        <NavLink to="/" home>
          {isDesktop ? (
            title || (
              <div className={styles.logoContainer}>
                <img
                  className={styles.logo}
                  src={image?.name}
                  alt="Logo website"
                  width={image?.width}
                  height={image?.height}
                />
              </div>
            )
          ) : (
            <FiHome />
          )}
        </NavLink>
        {children}

        {numResults !== undefined && location.startsWith('/search') && (
          <div className={styles.numResults}>
            <p>{numResults} results</p>
          </div>
        )}

        {backLabel && (
          <Link
            className={styles.backButton}
            to={backURL}
            state={{ previousId }}
          >
            <FiArrowLeft className={styles.backArrow} />
            <span>
              Back <span className={styles.backTitle}>to {backLabel}</span>
            </span>
          </Link>
        )}
      </div>
    </nav>
  );
}

export default NavBar;
