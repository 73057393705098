// import 'react-datepicker/dist/react-datepicker.css';

import { useDebouncedCallback } from '@react-hookz/web';
import { useState } from 'react';
import DatePicker from 'react-datepicker';

import { useMetadataValues } from '../api-hooks';
import { formatDateToString } from '../helpers';
import ClearButton from './ClearButton';
import FilterBox from './FilterBox';
import styles from './Input.module.css';
import { OPERATORS, useQueryParam } from './router-utils';

interface Props {
  name: string;
  label: string | boolean;
}

function DateFilter(props: Props) {
  const { name, label } = props;
  const param = useQueryParam(name);
  const dates = useMetadataValues(name).map((d) => new Date(d));
  dates.sort((a, b) => b.getTime() - a.getTime());

  const [filterDate, setFilterDate] = useState<Date | null>();

  const handleChange = useDebouncedCallback(
    (date: Date | null) => {
      if (date) {
        param.setValue(`${OPERATORS.like}${formatDateToString(date)}`);
      }
    },
    [param],
    500,
  );
  return (
    <FilterBox
      title={label}
      showTitle={label !== false}
      isActive={param.isActive}
    >
      <div className={styles.container}>
        <DatePicker
          selected={filterDate}
          onChange={(date) => {
            setFilterDate(date);
            handleChange(date);
          }}
          includeDates={dates}
          highlightDates={dates}
          placeholderText={formatDateToString(dates[0])}
          className={styles.textInput}
          dateFormat="yyyy-MM-dd"
        />
        <ClearButton
          disabled={!filterDate}
          onClick={() => {
            setFilterDate(null);
            param.remove();
          }}
        />
      </div>
    </FilterBox>
  );
}

export default DateFilter;
