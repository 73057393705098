import stylesToc from 'lib/TableOfContent.module.css';
import { Helmet } from 'react-helmet';

import BackToHelp from './BackToHelp';
import styles from './NeuroglancerTutorial.module.css';

interface Props {
  isDesktop: boolean;
}

function NeuroglancerTutorial(props: Props) {
  const { isDesktop } = props;
  return (
    <>
      <Helmet title="Tutorial - Neuro" />

      <div className={styles.wrapper}>
        {isDesktop ? (
          <aside className={stylesToc.tocAside}>
            <div className={stylesToc.tocSticky}>
              <BackToHelp />
            </div>
          </aside>
        ) : (
          <BackToHelp />
        )}

        <main className={styles.main}>
          <h1 className={stylesToc.tocTitle}>Guide to Neuroglancer</h1>
          <iframe
            className={styles.videoplayer}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Yg0ITKw5GiE?si=PVqTHgRy4ewo4D8k"
            title="Video on using Neuroglancer in the Human Organ Atlas"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </main>
      </div>
    </>
  );
}

export default NeuroglancerTutorial;
