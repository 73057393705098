import { useAllDatasets, useIcatSessionId } from 'lib/api-hooks';
import { useAppStore } from 'lib/stores';
import type { ComponentType } from 'react';
import { useEffect } from 'react';

import type { ProcessedDataset } from '../api-models';
import { useScrollToTop, useSearchParams } from '../hooks';
import NoResults from './NoResults';
import styles from './SearchResults.module.css';

interface DatasetCardProps {
  dataset: ProcessedDataset;
  sessionId: string;
}

interface CardProps {
  Card: ComponentType<DatasetCardProps>;
}

function SearchResults(props: CardProps) {
  useScrollToTop();
  const { Card } = props;
  const query = useSearchParams();

  const parametersInfo = query ? Object.fromEntries(query) : undefined;

  const filteredDatasets = useAllDatasets(parametersInfo);

  const sessionId = useIcatSessionId();

  const setNumResults = useAppStore((state) => state.setNumResults);
  const isEmpty = filteredDatasets.length === 0;

  useEffect(() => {
    setNumResults(filteredDatasets.length);
  }, [filteredDatasets.length, setNumResults]);

  return (
    <div className={styles.wrapper}>
      {isEmpty ? (
        <NoResults />
      ) : (
        filteredDatasets?.map((d) => (
          <Card key={d.id} dataset={d} sessionId={sessionId} />
        ))
      )}
    </div>
  );
}

export default SearchResults;
